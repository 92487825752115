<template>
  <div>
    <div class="operationControl flexdc" style="align-items: flex-start">
      <div class="searchbox">
        <el-button class="bgc-bv" size="mini" round @click="openAddEditStuden()">新增</el-button>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" size="small" tooltip-effect="dark" :height="tableHeight"
          style="width: 100%" stripe :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" min-width="80px" />
          <el-table-column label="科目标识" align="left" show-overflow-tooltip prop="itemCode" min-width="100px" />
          <el-table-column label="科目名称" align="left" show-overflow-tooltip prop="itemName" min-width="100px" />
          <el-table-column label="考核时长(分钟)" align="left" show-overflow-tooltip prop="duration" min-width="100px" />
          <el-table-column label="科目总分" align="left" show-overflow-tooltip prop="totalScore" min-width="100px" />
          <el-table-column label="考核方式" align="left" show-overflow-tooltip prop="evaluationMode" min-width="100px">
            <template slot-scope="scope">
              {{ scope.row.evaluationMode ? $setDictionary("PA_EVALUATION_MODE", scope.row.evaluationMode) : "" }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" width="140px" />
          <el-table-column label="操作" align="center" fixed="right" width="140px">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0px 5px" size="mini"
                @click="to_info_list(scope.row)">详情</el-button>
              <el-button type="text" style="padding: 0px 5px" size="mini"
                @click="openAddEditStuden(scope.row)">编辑</el-button>
              <el-button type="text" style="padding: 0px 5px" size="mini" @click="delete_data(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 弹框 - 新增&编辑 - 科目名称 -->
    <el-dialog :title="addEditStudentData.bankId ? '编辑科目名称' : '新增科目名称'" :visible.sync="addEditStudentData.dialogVisible"
      width="500px" @close="closeAddEditStuden">
      <el-form ref="addEditStudentData" label-width="120px" size="small" :rules="addEditStudentDataRules"
        :model="addEditStudentData">
        <el-form-item label="科目标识:" prop="itemCode">
          <el-input type="text" v-model="addEditStudentData.itemCode" clearable placeholder="请输入科目标识" />
        </el-form-item>
        <el-form-item label="科目名称:" prop="itemName">
          <el-input type="text" v-model="addEditStudentData.itemName" clearable placeholder="请输入科目名称" />
        </el-form-item>
        <el-form-item label="科目总分:" prop="totalScore">
          <el-input type="text" v-model="addEditStudentData.totalScore" clearable placeholder="请输入科目总分" />
        </el-form-item>
        <el-form-item label="考核时长(分钟):" prop="duration">
          <el-input-number v-model="addEditStudentData.duration" :min="1" :max="999" label="请输入考核时长"></el-input-number>
        </el-form-item>
        <el-form-item label="考核方式:" prop="evaluationMode">
          <el-select clearable size="small" v-model="addEditStudentData.evaluationMode" placeholder="请输入考核方式">
            <el-option v-for="item in evaluationModeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeAddEditStuden" class="bgc-bv">取 消</el-button>
        <el-button size="small" @click="sureAddEditStuden" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";
export default {
  name: "questionBank_info_subject",
  components: {
    Empty,
    PageNum
  },
  props: {
    // 题库id
    bankId: {
      type: String,
      default: "",
    },
    // 科目id
    subjectId: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  data() {
    return {
      // 添加&新增 - 弹框数据
      addEditStudentData: {
        dialogVisible: false, // 弹框状态
        bankId: "", // 题库id
        itemCode: "", // 科目标识
        itemName: "", // 科目名称
        totalScore: "", // 科目总分
        duration: "", // 考核时长
        evaluationMode: "", // 考核方式
      },
      // 申请&新增弹框数据校验
      addEditStudentDataRules: {
        itemCode: [
          { required: true, message: "请选择科目标识", trigger: "change" },
        ],
        itemName: [
          { required: true, message: "请输入科目名称", trigger: "blur" },
        ],
        totalScore: [
          { required: true, message: "请输入科目总分", trigger: "blur" },
        ],
        duration: [
          { required: true, message: "请输入考核时长", trigger: "blur" },
        ],
        evaluationMode: [
          { required: true, message: "请输入考核方式", trigger: "blur" },
        ],
      },
      // 考核方式 - 拉下数据
      evaluationModeList: [],
    };
  },
  watch: {},
  computed: {
  },
  mounted() { },
  created() {
    this.getDictionary();
    this.getData();
  },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        bankId: this.bankId,
        subjectId: this.subjectId,
      };
      this.doFetch(
        {
          url: "/biz/bank/pa-question-bank/itemList",
          params,
          pageNum,
        }, true, 10
      );
    },
    // 详情
    to_info_list(row) {
      this.$router.push({
        path: "/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list",
        query: {
          bankId: this.bankId, // 题库id
          subjectId: this.subjectId, // 科目id
          itemId: row.itemId, // 考核项目id
          itemCode: row.itemCode, // 科目标识
          itemName: row.itemName, // 科目名称
        }
      })
    },
    // 获取 - 字典
    getDictionary() {
      // 考核方式
      let evaluationMode = this.$setDictionary("PA_EVALUATION_MODE", "list");
      for (const key in evaluationMode) {
        this.evaluationModeList.push({
          value: key,
          label: evaluationMode[key],
        });
      }
    },
    // 新增编辑 - 弹框 - 打开
    openAddEditStuden(row) {
      if (row) {
        this.addEditStudentData = { ...this.addEditStudentData, ...row };
      }
      this.addEditStudentData.dialogVisible = true;
    },
    // 新增编辑 - 弹框 - 关闭
    closeAddEditStuden() {
      this.addEditStudentData = this.$options.data().addEditStudentData;
    },
    // 新增编辑 - 弹框 - 确定
    sureAddEditStuden() {
      this.$refs['addEditStudentData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/bank/pa-question-bank/saveOrUpdateItem", {
            ...this.addEditStudentData,
            bankId: this.bankId,
            subjectId: this.subjectId,
          }, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.closeAddEditStuden();
              this.getData(this.pageNum);
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
    // 删除
    delete_data(row) {
      this.$post("/biz/bank/pa-question-bank/delItem", {
        itemId: row.itemId
      }, 3000, true, 10)
        .then((res) => {
          this.$message.success('操作成功')
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>
