<template>
  <div class="pageContol templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">题库管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">题库内容</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-tabs v-model="activeName" @tab-click="tabChange">
              <el-tab-pane label="科目一" name="type_1">
                <type_1 ref="type_1" :bankId="bankId" :subjectId="1" />
              </el-tab-pane>
              <el-tab-pane label="科目二" name="type_2">
                <type_1 ref="type_2" :bankId="bankId" :subjectId="2"/>
              </el-tab-pane>
              <el-tab-pane label="科目三" name="type_3">
                <type_1 ref="type_3" :bankId="bankId" :subjectId="3"/>
              </el-tab-pane>
              <el-tab-pane label="科目四" name="type_4">
                <type_1 ref="type_4" :bankId="bankId" :subjectId="4"/>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import type_1 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_subject.vue"; //科目详情
export default {
  name: "examinationAffairsManage_examPlan_info",
  components: {
    type_1
  },
  data() {
    return {
      // tap选项卡的选中name
      activeName: "type_1",
      // 题库id
      bankId: "",
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    this.bankId = this.$route.query?.bankId ?? "";
  },
  methods: {
    // 点击tabs选项卡
    tabChange(tab, event) {
      this.activeName = tab.name;
    },
  },
};
</script>
<style lang="less" scoped></style>
